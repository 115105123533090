import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import {
  Banner,
  Button,
  Card,
  Grid,
  Head,
  Layout,
  Link,
  Section,
  Separator,
  Timeline,
  Typography,
} from "../components"

export const MissionsPageTemplate = ({
  title,
  subheading,
  section1,
  section2,
  section3,
}) => (
  <>
    <Banner interior title={title} subheading={subheading} />
    <Section>
      <Typography tag="h2" variant="large">
        {section1.title}
      </Typography>
      <Timeline items={section1.list} />
    </Section>
    <Section
      variant="image-left"
      image={
        section2.image.childImageSharp
          ? section2.image.childImageSharp.fluid
          : null
      }
    >
      <Typography tag="h2" variant="large">
        {section2.title}
      </Typography>
      <Separator />
      {section2.content.map((content, i) => (
        <Typography key={`section-2-s-${i}`}>{content}</Typography>
      ))}
      <Link to={`/${section2.link.to}`}>{section2.link.label}</Link>
    </Section>

    <Section>
      <Grid container>
        {section3.cards.map((card, i) => (
          <Grid key={`card-${i}`}>
            <Card title={card.title} variant={card.variant || "default"}>
              {card.list && (
                <ul>
                  {card.list.map((item, j) => (
                    <li key={`card-${i}-item-${j}`}>{item}</li>
                  ))}
                </ul>
              )}
            </Card>
          </Grid>
        ))}
      </Grid>
      <Button to="/temoignages" variant="outlined">
        Voir tous les avis
      </Button>
    </Section>
  </>
)

MissionsPageTemplate.propTypes = {
  title: PropTypes.string,
  subheading: PropTypes.string,
  section1: PropTypes.object,
  section2: PropTypes.object,
  section3: PropTypes.object,
}

const MissionsPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  return (
    <Layout>
      <Head title={frontmatter.title} description={frontmatter.subheading} />
      <MissionsPageTemplate
        title={frontmatter.title}
        subheading={frontmatter.subheading}
        section1={frontmatter.section_missions_1}
        section2={frontmatter.section_missions_2}
        section3={frontmatter.section_missions_3}
      />
    </Layout>
  )
}

MissionsPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default MissionsPage

export const pageQuery = graphql`
  query MissionsPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "missions-page" } }) {
      frontmatter {
        title
        subheading
        section_missions_1 {
          title
          list {
            title
            content
          }
        }
        section_missions_2 {
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          title
          content
          link {
            label
            to
          }
        }
        section_missions_3 {
          cards {
            title
            list
            variant
          }
        }
      }
    }
  }
`
